<template>
  <div>
    <div v-if="users === null">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </div>
    <div v-if="users !== null">
      <v-list>
        <template v-for="(user, index) in slicedUsers">
          <UserListItem
            :key="user.uid"
            :user="user"
          />
          <v-divider
            v-if="index < (slicedUsers.length - 1)"
            :key="index"
            inset
          />
        </template>
      </v-list>
      <div
        v-if="users.length > slicedUsers.length"
        v-intersect.quiet="loadMore"
      />
    </div>
  </div>
</template>

<script>
  import UserListItem from "../components/UserListItem"

  export default {
    name: 'UserList',
    props: {
      users:  {
        type: Array, 
        default: () => []
      },
      length: {
        type: Number, 
        default: 0
      }
    },
    components: {
      UserListItem
    },
    data: () => ({
      tab: null,
      startIndex: 0,
      step: 50,
      isLoadingMore: false,
    }),
    computed: {
      slicedUsers: function() {
        return this.users.slice(this.startIndex, this.length)
      }
    },
    methods: {
      loadMore: function() {
        this.length = parseInt(this.length) + parseInt(this.step)
      }
    }
  }
</script>
